import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

class ProductThumbs extends HTMLElement {
  constructor() {
    super()

    this.carousel = this.querySelector('[data-carousel]')
    console.log('this.carousel', this.carousel)
    this.initSwiper()
  }

  initSwiper() {
    const swiper = new Swiper('.swiper', {
      modules: [Navigation, Pagination],
      direction: 'vertical',
      loop: true,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    })
  }
}

if (!window.customElements.get('product-thumbs')) {
  window.customElements.define('product-thumbs', ProductThumbs)
}